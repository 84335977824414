import React, { useState, useEffect, useMemo } from 'react'
import ReactTooltip from 'react-tooltip'
import { useSelector, useDispatch } from 'react-redux'
import {
  PLAN_STATUS,
  MY_SERVICES,
  TV_PLAN_CARDS,
  TV_PLAN_EVENTS,
  COMPONENTS_RENDER,
  DYNAMIC_RENDER_COMPONENTS,
  FIREBASE_EVENTS,
  EVENT_STATUS,
} from 'app-constants'
import { Card, Button, Alert } from 'components'
import { STATICS } from 'statics'
import { fetchContractDetails, fetchTvPlansActivationLink } from 'slices/contract'
import { getTvButtonInfo, openNewTab } from 'utils'
import { getContractFile, useFirebaseEvent } from 'hooks'
import { Pending } from './components'
import { DOWNLOAD_CONTRACT_ERROR_MESSAGE, DOWNLOAD_CONTRACT_ERROR_TITLE } from './constants'
import './style.scss'

const SHOW_TV_PASSWORD_TOOLTIP_TEXT = 'Mostrar senha'
const HIDE_TV_PASSWORD_TOOLTIP_TEXT = 'Esconder senha'

export function PlanDetails() {
  const [pending, setPending] = useState(false)
  const [error, setError] = useState('')
  const [isLoadingDownloadButton, setIsLoadingDownloadButton] = useState(false)
  const [showTvPassword, setShowTvPassword] = useState(false)
  const { selected: contract, detail: contractDetails, activationLinks } = useSelector(
    ({ contract }) => contract
  )
  const dispatch = useDispatch()
  const { sendEvent } = useFirebaseEvent()

  const title = useMemo(() => {
    if (pending)
      return (
        <h3 className='title'>
          Acompanhe o status <br />
          do seu pedido
        </h3>
      )

    return (
      <h3 className='title'>
        Detalhamento <br />
        do meu plano
      </h3>
    )
  }, [pending])

  useEffect(() => {
    const isPendentContract = contractDetails?.status === PLAN_STATUS.ST_CONT_EM_ATIVACAO

    if (!contractDetails) dispatch(fetchContractDetails(contract.id))
    if (isPendentContract) setPending(true)
    dispatch(fetchTvPlansActivationLink())
  }, [dispatch, contractDetails, contract.id])

  async function handleDownloadContract() {
    try {
      setIsLoadingDownloadButton(true)
      const { data } = await getContractFile(contract.id)
      openNewTab(data)
      sendEvent(FIREBASE_EVENTS.CLICK_ON_DOWNLOAD_CONTRACT_PDF)
    } catch (err) {
      sendEvent(FIREBASE_EVENTS.CLICK_ON_DOWNLOAD_CONTRACT_PDF, EVENT_STATUS.ERROR)
      console.error(err)
      setError({
        title: DOWNLOAD_CONTRACT_ERROR_TITLE,
        text: DOWNLOAD_CONTRACT_ERROR_MESSAGE,
      })
    } finally {
      setIsLoadingDownloadButton(false)
    }
  }

  function handleAlertClose() {
    setError(null)
  }

  function handleClickShowTvPassword() {
    setShowTvPassword(!showTvPassword)
  }

  function getIsTvPlanCardAdditional(itemName) {
    return Object.values(TV_PLAN_CARDS).includes(itemName)
  }

  function getTvPlanActivationLink(itemCode) {
    const activationLink = activationLinks.find(({ tvPlanKey }) => tvPlanKey === itemCode)

    return activationLink?.activationLink || ''
  }

  function handleSendEvent(event) {
    sendEvent(event)
  }

  function renderActionButton(itemCode, activeSva, urlDocument, status) {
    const isTvPlanAdditional = getIsTvPlanCardAdditional(itemCode)
    const activationLink = isTvPlanAdditional ? getTvPlanActivationLink(itemCode) : null
    const buttonInfo = getTvButtonInfo({ activeSva, urlDocument, status }, activationLink)
    const event = isTvPlanAdditional ? TV_PLAN_EVENTS[itemCode] : MY_SERVICES[itemCode].eventName

    if (!buttonInfo) return null

    const { label, link } = buttonInfo

    return (
      <a href={link} rel='noreferrer' target='_blank' className='see-details'>
        <Button
          color='primary'
          outlined
          className='see-details'
          onClick={() => handleSendEvent(event)}
          data-cy='button-see-details'
        >
          {label}
        </Button>
      </a>
    )
  }

  function renderAdditionals() {
    if (!contractDetails.contractAdditionals.length) return null
    return (
      <>
        <p className='subtitle'>Serviços adicionais</p>
        {contractDetails.contractAdditionals.map(
          ({ itemCode, urlDocument, urlLogo, urlImage, itemName, activeSva, status }) => {
            const urlSvaLogo = urlLogo || urlImage

            return (
              <Card className='card -additional -flex' key={itemCode}>
                <div className='container-info'>
                  {urlSvaLogo && <img src={urlSvaLogo} alt={itemName} className='img' />}
                  <p className='text'>{itemName}</p>
                </div>
                <div className='container-buttons'>
                  {renderActionButton(itemCode, activeSva, urlDocument, status)}
                </div>
              </Card>
            )
          }
        )}
      </>
    )
  }

  function renderShowTvPasswordButton() {
    return (
      <>
        <div
          className='icon'
          data-for='tv-password-tip'
          data-tip={showTvPassword ? HIDE_TV_PASSWORD_TOOLTIP_TEXT : SHOW_TV_PASSWORD_TOOLTIP_TEXT}
        >
          {showTvPassword ? (
            <STATICS.EYE onClick={handleClickShowTvPassword} />
          ) : (
            <STATICS.EYE_SLASH onClick={handleClickShowTvPassword} />
          )}
        </div>
        <ReactTooltip id='tv-password-tip' />
      </>
    )
  }

  function renderDownloadButton() {
    const shouldRenderDownloadButton =
      COMPONENTS_RENDER[DYNAMIC_RENDER_COMPONENTS.CONTRACT_PDF_DOWNLOAD]

    return (
      shouldRenderDownloadButton && (
        <Button
          color='primary'
          className='button'
          outlined
          onClick={handleDownloadContract}
          isLoading={isLoadingDownloadButton}
        >
          Download do PDF
        </Button>
      )
    )
  }

  if (!contractDetails) return null

  return (
    <div className='plan-details-component'>
      {title}
      {!!error && (
        <Alert
          className='alert'
          severity='error'
          size='large'
          title={error.title}
          text={error.text}
          onClose={handleAlertClose}
        />
      )}
      {pending && <Pending />}

      <p className='subtitle'>Plano contratado</p>
      <Card className='card'>
        <p className='text'>{contractDetails.planName}</p>
        <p className='text'>Valor: {contractDetails.formattedTotalPrice}</p>
        {contractDetails.additionalTvPointsQuantity > 0 && (
          <p className='text'>
            {contractDetails.additionalTvPointsQuantity} Ponto opcional (HD):{' '}
            {contractDetails.formattedAdditionalTvPointsTotalPrice}
          </p>
        )}
        <p className='text'>Status: {PLAN_STATUS[contract.contractStatus]}</p>
      </Card>

      {!!contractDetails.tvPassword && (
        <>
          <p className='subtitle'>Login da TV | Sumicity Play</p>
          <Card className='card'>
            <p className='text'>Usuário: {contractDetails.tvLogin}</p>
            <div className='tv-password-container'>
              <p className='text'>Senha :&nbsp;</p>
              <p className={showTvPassword ? 'password' : 'password -hidden'}>
                {contractDetails.tvPassword}
              </p>
              {renderShowTvPasswordButton()}
            </div>
          </Card>
        </>
      )}

      {renderAdditionals()}
      <Card className='card -contract -flex'>
        <div className='content'>
          <p className='title'>Meu contrato</p>
          <p className='text'>{contractDetails.planName}</p>
        </div>
        {!pending && renderDownloadButton()}
      </Card>
    </div>
  )
}
