import React from 'react'
import { DASHBOARD_COMPONENTS_NAMES, TV_PLAN_CARDS } from 'app-constants'
import {
  AddressCard,
  BillsCard,
  ContactButton,
  PersonalInfoCard,
  PhoneCard,
  SubscriptionCard,
} from 'components'
import { FileUploadCard } from '../file-upload-card'
import { FileUploadWhatsappCard } from '../file-upload-whatsapp-card'
import { ModemCard } from '../modem-card'
import { FAQCard } from '../faq-card'
import { PasswordCard } from '../password-card'
import { UnlockCard } from '../unlock-card'
import { MyServicesCard } from '../my-services-card'
import { HeroCard } from '../hero-card'
import { VirtualSupportCard } from '../virtual-support-card'
import { MyCallsCard } from '../my-calls-card'
import { TvPlanCard } from '../tv-plan'
import { ReferFriendsCard } from '../refer-friend'
import { SpeedTestCard } from '../speed-test-card'
import { NegotiationCard } from '../negotiation-card'
import { NegotiationPortal } from '../negotiation-portal'

export function DashboardFactory({ type, props }) {
  switch (type) {
    case DASHBOARD_COMPONENTS_NAMES.CHAT:
      return <ContactButton {...props} />
    case DASHBOARD_COMPONENTS_NAMES.BILLS_CARD:
      return <BillsCard {...props} />
    case DASHBOARD_COMPONENTS_NAMES.PERSONAL_INFO_CARD:
      return <PersonalInfoCard {...props} />
    case DASHBOARD_COMPONENTS_NAMES.ADDRESS_CARD:
      return <AddressCard {...props} />
    case DASHBOARD_COMPONENTS_NAMES.FILE_UPLOAD_CARD:
      return <FileUploadCard {...props} />
    case DASHBOARD_COMPONENTS_NAMES.FILE_UPLOAD_WHATSAPP_CARD:
      return <FileUploadWhatsappCard {...props} />
    case DASHBOARD_COMPONENTS_NAMES.MODEM_CARD:
      return <ModemCard {...props} />
    case DASHBOARD_COMPONENTS_NAMES.SUBSCRIPTION_CARD:
      return <SubscriptionCard {...props} />
    case DASHBOARD_COMPONENTS_NAMES.PHONE_CARD:
      return <PhoneCard {...props} />
    case DASHBOARD_COMPONENTS_NAMES.FAQ_CARD:
      return <FAQCard {...props} />
    case DASHBOARD_COMPONENTS_NAMES.PASSWORD_CARD:
      return <PasswordCard {...props} />
    case DASHBOARD_COMPONENTS_NAMES.UNLOCK_CARD:
      return <UnlockCard {...props} />
    case DASHBOARD_COMPONENTS_NAMES.MYSERVICES_CARD:
      return <MyServicesCard {...props} />
    case DASHBOARD_COMPONENTS_NAMES.HERO_CARD:
      return <HeroCard {...props} />
    case DASHBOARD_COMPONENTS_NAMES.VIRTUAL_SUPPORT:
      return <VirtualSupportCard {...props} />
    case DASHBOARD_COMPONENTS_NAMES.MYCALLS_CARDS:
      return <MyCallsCard {...props} />
    case DASHBOARD_COMPONENTS_NAMES.GLOBOPLAY_CARD:
      return <TvPlanCard {...props} tvPlanName={TV_PLAN_CARDS.GLOBO_PLAY} />
    case DASHBOARD_COMPONENTS_NAMES.GLOBOPLAY_CARD_WITH_ADS:
      return <TvPlanCard {...props} tvPlanName={TV_PLAN_CARDS.GLOBO_PLAY_DEFAULT_WITH_ADS} />
    case DASHBOARD_COMPONENTS_NAMES.GLOBOPLAY_CARD_DEFAULT:
      return <TvPlanCard {...props} tvPlanName={TV_PLAN_CARDS.GLOBO_PLAY_DEFAULT} />
    case DASHBOARD_COMPONENTS_NAMES.GLOBOPLAY_CARD_PREMIUM:
      return <TvPlanCard {...props} tvPlanName={TV_PLAN_CARDS.GLOBO_PLAY_PREMIUM} />
    case DASHBOARD_COMPONENTS_NAMES.TELECINE_CARD:
      return <TvPlanCard {...props} tvPlanName={TV_PLAN_CARDS.TELECINE} />
    case DASHBOARD_COMPONENTS_NAMES.PREMIERE:
      return <TvPlanCard {...props} tvPlanName={TV_PLAN_CARDS.PREMIERE} />
    case DASHBOARD_COMPONENTS_NAMES.GLOBOPLAY_PLUS_CARD:
      return <TvPlanCard {...props} tvPlanName={TV_PLAN_CARDS.GLOBO_PLAY_MAIS_CANAIS} />
    case DASHBOARD_COMPONENTS_NAMES.INDICATE_FRIEND:
      return <ReferFriendsCard {...props} />
    case DASHBOARD_COMPONENTS_NAMES.SPEED_TEST:
      return <SpeedTestCard {...props} />
    case DASHBOARD_COMPONENTS_NAMES.NEGOTIATION_CARD:
      return <NegotiationCard {...props} />
    case DASHBOARD_COMPONENTS_NAMES.GIGA_TV_CARD:
      return <TvPlanCard {...props} tvPlanName={TV_PLAN_CARDS.GIGA_TV} />
    case DASHBOARD_COMPONENTS_NAMES.NEGOTIATION_PORTAL:
      return <NegotiationPortal {...props} />
    default:
      return null
  }
}
