import React from 'react'
import { useSelector } from 'react-redux'
import {
  STORES,
  APP_STORE_LINK,
  PLAY_STORE_LINK,
  BRAND_DISPLAY_NAME,
  PRIVACY_POLICY_URL,
  TERMS_OF_USAGE,
  INFORMATION_SECURITY,
} from 'app-constants'
import { STATICS } from 'statics'
import './style.scss'

export function Footer() {
  const selectedContract = useSelector(({ contract }) => contract.selected)

  function handleOpenApp(store) {
    const link = store === STORES.APPLE ? APP_STORE_LINK : PLAY_STORE_LINK

    window.open(link)
  }

  function renderComponent() {
    return (
      <footer className='footer-component'>
        <div className='container'>
          <div className='info'>
            <div className='icon'>
              <STATICS.SHIELD_LOCK />
            </div>
            <div className='wrapper'>
              <p className='text'>{`Você está em um ambiente seguro ${BRAND_DISPLAY_NAME}.`}</p>
              <p className='text'>
                {`© ${new Date().getFullYear()}. ${BRAND_DISPLAY_NAME}. Todos os direitos reservados.`}
              </p>
            </div>
          </div>
          <div className='badges'>
            <a href={TERMS_OF_USAGE} className='link' target='_blank' rel='noreferrer'>
              Termos de uso
            </a>
            <a href={INFORMATION_SECURITY} className='link' target='_blank' rel='noreferrer'>
              Segurança da informação
            </a>
            <a href={PRIVACY_POLICY_URL} className='link' target='_blank' rel='noreferrer'>
              Política de Privacidade
            </a>
            {!!APP_STORE_LINK && (
              <button type='button' onClick={() => handleOpenApp(STORES.APPLE)} className='link'>
                <STATICS.APPLE_BADGE alt='Logo App Store' className='icon' />
              </button>
            )}
            {!!PLAY_STORE_LINK && (
              <button type='button' onClick={() => handleOpenApp(STORES.ANDROID)} className='link'>
                <STATICS.GOOGLE_BADGE alt='Logo Google Play Store' className='icon' />
              </button>
            )}
          </div>
        </div>
      </footer>
    )
  }

  return !!selectedContract && renderComponent()
}
