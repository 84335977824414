export const VIRTUAL_SUPPORT_STAGES = {
  ADDRESS_CONFIRMATION: 'address_confirmation',
  AUTO_SUPPORT: 'auto_support',
  CHECK_MODEM_LIGHTS: 'check_modem_lights',
  INITIAL: 'initial',
  MASSIVE_WARNING: 'initial',
  VIRTUAL_SUPPORT_ALERT: 'initial',
  REDIRECT_CHAT: 'redirect_chat',
  SELECT_ADDRESS: 'select_address',
  SERVICE_ORDER: 'service_order',
  TALK_TO_ATTENDANT: 'talk_attendant',
  SCHEDULING_SUCCESS: 'scheduling_success',
  AFTER_TIME: 'after_time',
  SCHEDULING_SERVICE_ORDER: 'scheduling_service_order',
  SELECT_SERVICES: 'select_services',
  RESCHEDULE_CONFIRMATION: 'reschedule_confirmation',
  SCHEDULE_CONFIRMATION: 'schedule_confirmation',
  RESCHEDULING_SERVICE_ORDER: 'rescheduling_service_order',
  CANCEL_CONFIRMATION: 'cancel_confirmation',
  CANCEL_SUCCESS: 'cancel_success',
}

export const OS_RESCHEDULE_MESSAGE = 'Clique aqui para reagendar sua instalação'

export const OS_INFO_MESSAGE = 'Caso precise de mais informações, entre em contato conosco.'

export const NEW_VIRTUAL_SUPPORT_STAGES = {
  INITIAL: 'initial',
  MASSIVE_WARNING: 'massiveWarning',
  REDIRECT_CHAT: 'redirectChat',
  SERVICE_ORDER: 'service_order',
  RESCHEDULING_SERVICE_ORDER: 'rescheduling_service_order',
  RESCHEDULE_CONFIRMATION: 'reschedule_confirmation',
  SCHEDULING_SUCCESS: 'scheduling_success',
  CANCEL_SUCCESS: 'cancel_success',
  SIGNAL_VERIFY: 'signal_verify',
  STABLE_SIGNAL: 'stable_signal',
  UNSTABLE_SIGNAL: 'unstable_signal',
  MODEM_EQUIPMENT_LIST: 'modem_equipment_list',
  MODEM_RESET: 'modem_reset',
  MODEM_AWAIT: 'modem_await',
  MODEM_CABLE_RECONNECT: 'modem_cable_reconnect',
  MODEM_ALMOST_DONE: 'modem_almost_done',
  MODEM_FINISH: 'modem_finish',
  SCHEDULING_SERVICE_ORDER: 'scheduling_service_order',
  SCHEDULE_CONFIRMATION: 'schedule_confirmation',
  SELECT_ADDRESS: 'select_address',
  SELECT_ADDRESS_CONFIRMATION: 'select_address_confirmation',
}
