import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import CopyToClipboard from 'react-copy-to-clipboard'
import ReactTooltip from 'react-tooltip'
import { setModal } from 'slices/modal'
import { setDrawer, setDrawerAlert } from 'slices/drawer'
import { fetchBankSlips } from 'slices/customer'
import {
  BILLS_PAYMENT_TYPE,
  BILLS_TAGS,
  COMPONENTS_RENDER,
  DRAWER_KEY,
  FIREBASE_EVENTS,
  MY_BILLS_PAGE_TAB_LABEL,
  ROUTES,
} from 'app-constants'
import { STATICS } from 'statics'
import { generatePaymentLink, useFirebaseEvent } from 'hooks'
import { Button } from 'components/button'
import {
  CARD_ERROR_MESSAGE,
  COPIED_BAR_CODE_MESSAGE,
  COPY_BILLET_CODE_MESSAGE,
  INITIAL_LOADING_STATE,
  PIX_ERROR_MESSAGE,
} from '../../constants'
import './style.scss'

export function Success({ contractId }) {
  const [barTip, setBarTip] = useState(COPY_BILLET_CODE_MESSAGE)
  const [isLoading, setIsLoading] = useState(INITIAL_LOADING_STATE)
  const [slip, setSlip] = useState({})
  const [paymentLink, setPaymentLink] = useState('')
  const { sendEvent } = useFirebaseEvent()
  const { push } = useHistory()
  const { bankSlips } = useSelector(state => state.customer.financial)
  const idContract = useSelector(state => state.contract.selected.id)
  const dispatch = useDispatch()

  useEffect(() => {
    const negotiatedSlip = bankSlips.content.find(({ tags }) =>
      tags?.includes(BILLS_TAGS.BREACH_OF_AGREEMENT)
    )

    setSlip(negotiatedSlip || {})
  }, [bankSlips])

  useEffect(() => {
    dispatch(fetchBankSlips(idContract))
  }, [idContract, dispatch])

  useEffect(() => {
    async function getPaymentLink() {
      try {
        const { id } = slip

        const {
          data: { paymentLink: paymentRedirect },
        } = await generatePaymentLink({
          invoiceId: id,
          paymentMethod: BILLS_PAYMENT_TYPE.BANK_SLIP,
        })

        setPaymentLink(paymentRedirect)
      } catch (error) {
        console.error(error)
      }
    }

    if (slip?.id) getPaymentLink()
  }, [slip])

  useEffect(() => {
    sendEvent(FIREBASE_EVENTS.BILLET_NEGOTIATION_SUCCESS)
  }, [sendEvent])

  async function handlePixButtonClick() {
    try {
      setIsLoading({ ...isLoading, pix: true })

      const {
        data: { paymentLink: pixLink, qrCodeText },
      } = await generatePaymentLink({ invoiceId: slip.id, paymentMethod: BILLS_PAYMENT_TYPE.PIX })

      if (qrCodeText) {
        dispatch(setModal({ key: 'pix_payment', data: { qrCode: qrCodeText } }))
        dispatch(setDrawer(DRAWER_KEY.NONE))
      } else if (pixLink) {
        window.open(pixLink)
      } else {
        handleOpenPaymentLink(PIX_ERROR_MESSAGE)
      }
    } catch (error) {
      console.error(error)
      handleOpenPaymentLink(PIX_ERROR_MESSAGE)
    } finally {
      setIsLoading({ ...isLoading, pix: false })
    }
  }

  function handleOpenPaymentLink(errorMessage) {
    if (paymentLink) window.open(paymentLink)
    else
      dispatch(
        setDrawerAlert({
          severity: 'error',
          title: errorMessage,
        })
      )
  }

  async function handleCardButtonClick() {
    sendEvent(FIREBASE_EVENTS.CLICK_CREDIT_CARD_BILLET_PAYMENT)
    handleOpenPaymentLink(CARD_ERROR_MESSAGE)
  }

  function handleBilletButtonClick() {
    setBarTip(COPIED_BAR_CODE_MESSAGE)
    sendEvent(FIREBASE_EVENTS.CLICK_COPY_BILLET_CODE)
  }

  function handleBilletButtonMouseLeave() {
    setBarTip(COPY_BILLET_CODE_MESSAGE)
  }

  function handleClick() {
    sendEvent(FIREBASE_EVENTS.CLICK_ON_SEE_BILLETS)
    dispatch(setDrawer(''))
    dispatch(fetchBankSlips(contractId))
    push(`${ROUTES.INVOICES.PATH}#${MY_BILLS_PAGE_TAB_LABEL.INVOICES}`)
  }

  function handleGoHomeClick() {
    dispatch(setDrawer(''))
  }

  function renderCreditCardButton() {
    const shouldRenderCreditPayment = COMPONENTS_RENDER.CREDIT_CARD_PAYMENT_BUTTON

    if (shouldRenderCreditPayment)
      return (
        <Button
          className='button card'
          isLoading={isLoading.card}
          disabled={isLoading.card}
          icon={!isLoading.card && <STATICS.CREDIT_CARD_PAYMENT_ICON />}
          color='primary'
          onClick={handleCardButtonClick}
        >
          <span className='text'>Pagar com Cartão</span>
        </Button>
      )

    return null
  }

  function renderPayments() {
    const hasInvoice = !!slip.id
    const { digitableLine } = slip

    if (hasInvoice)
      return (
        <div className='container-payments'>
          <span className='title'>Pague agora</span>
          <div className='container-buttons'>
            <CopyToClipboard text={digitableLine}>
              <Button
                className='button billet'
                icon={<STATICS.SLIP_ICON />}
                color='primary'
                onMouseEnter={handleBilletButtonMouseLeave}
                onClick={handleBilletButtonClick}
                data-for='barcode-tip'
                data-tip=''
              >
                <span className='text'>Pague com boleto</span>
                <ReactTooltip id='barcode-tip' getContent={() => barTip} />
              </Button>
            </CopyToClipboard>
            <Button
              isLoading={isLoading.pix}
              disabled={isLoading.pix}
              className='button pix'
              icon={!isLoading.pix && <STATICS.PIX_PAYMENT_ICON />}
              color='primary'
              onClick={handlePixButtonClick}
            >
              <span className='text'>Pagar com PIX</span>
            </Button>
            {renderCreditCardButton()}
          </div>
        </div>
      )

    return null
  }

  function renderGoToInvoicesButton() {
    const hasInvoice = !!slip.id

    if (hasInvoice) return null

    return (
      <Button onClick={handleClick} className='button accept' color='primary'>
        Ir para minhas faturas
      </Button>
    )
  }

  function renderActions() {
    return (
      <div className='container-buttons'>
        {renderGoToInvoicesButton()}
        <Button onClick={handleGoHomeClick} outlined className='button home' color='primary'>
          Voltar para home
        </Button>
      </div>
    )
  }

  return (
    <div className='container-success'>
      <div className='container-info'>
        <div className='container-icon'>
          <STATICS.SUCCESS />
        </div>
        <span className='title'>Negociação confirmada!</span>
        <div className='container-description'>
          <span className='description'>
            Esta negociação foi concluída e gerou um novo boleto que{' '}
            <strong>deve ser pago até o próximo dia útil</strong>
          </span>
          <span className='description'>
            Caso seu pagamento não seja identificado, esta{' '}
            <strong>negociação será cancelada</strong> e suas faturas voltarão a ser cobradas!
          </span>
          <span className='description'>
            Se a sua negociação foi parcelada, não se preocupe porque as demais parcelas virão junto
            com as faturas seguintes.
          </span>
        </div>
      </div>
      {renderPayments()}
      {renderActions()}
    </div>
  )
}
