import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { ProtocolList } from './components'
import { STAGES } from './constants'

export function ProtocolDrawer() {
  const [protocols, setProtocols] = useState([])
  const { stage } = useSelector(({ drawer }) => drawer)
  return stages({ protocols, setProtocols })[stage]
}

function stages({ ...props }) {
  return {
    [STAGES.INITIAL]: <ProtocolList {...props} />,
  }
}
