export const DASHBOARD_COMPONENTS_NAMES = {
  CHAT: 'Chat',
  BILLS_CARD: 'BillsCard',
  PERSONAL_INFO_CARD: 'PersonalInfoCard',
  ADDRESS_CARD: 'AddressCard',
  FILE_UPLOAD_CARD: 'FileUploadCard',
  FILE_UPLOAD_WHATSAPP_CARD: 'FileUploadWhatsappCard',
  MODEM_CARD: 'ModemCard',
  SUBSCRIPTION_CARD: 'SubscriptionCard',
  REFER_FRIEND_CARD: 'ReferFriendCard',
  PHONE_CARD: 'PhoneCard',
  FAQ_CARD: 'FAQCard',
  PASSWORD_CARD: 'PasswordCard',
  UNLOCK_CARD: 'UnlockCard',
  MYSERVICES_CARD: 'MyServicesCard',
  HERO_CARD: 'HeroCard',
  VIRTUAL_SUPPORT: 'VirtualSupport',
  MYCALLS_CARDS: 'MyCallsCard',
  GLOBOPLAY_CARD: 'GloboPlayCard',
  GLOBOPLAY_CARD_WITH_ADS: 'GloboPlayCardWithAds',
  GLOBOPLAY_CARD_DEFAULT: 'GloboPlayCardDefault',
  GLOBOPLAY_CARD_PREMIUM: 'GloboPlayCardPremium',
  INDICATE_FRIEND: 'ReferFriendsCard',
  PREMIERE: 'PremiereCard',
  SPEED_TEST: 'SpeedTest',
  NEGOTIATION_CARD: 'NegotiationCard',
  GIGA_TV_CARD: 'GigaTvCard',
  TELECINE_CARD: 'TelecineCard',
  GLOBOPLAY_PLUS_CARD: 'GloboplayPlusCard',
  NEGOTIATION_PORTAL: 'NegotionalPortal',
}
