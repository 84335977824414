import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setDrawer } from 'slices/drawer'
import { Button } from 'components'
import { STATICS } from 'statics'
import { DRAWER_KEY, MY_BILLS_PAGE_TAB_LABEL, ROUTES } from 'app-constants'
import './style.scss'

export function SuspendedContractWarning() {
  const dispatch = useDispatch()
  const { push } = useHistory()

  function handlePayNowClick() {
    const invoiceUrl = `${ROUTES.INVOICES.PATH}#${MY_BILLS_PAGE_TAB_LABEL.BANKSLIPS}`
    push(invoiceUrl)
    window.scrollTo(0, 0)
    dispatch(setDrawer(DRAWER_KEY.NONE))
  }

  return (
    <div className='suspended-contract-warning'>
      <span className='icon'>
        <STATICS.INFO />
      </span>
      <div className='info'>
        <p className='text'>Notamos que seu contrato está suspenso por débito pendente.</p>
        <p className='text'>Que tal regularizar agora para voltar a navegar?</p>
      </div>
      <div className='actions'>
        <Button color='primary' size='large' onClick={handlePayNowClick}>
          Pagar agora
        </Button>
      </div>
    </div>
  )
}
