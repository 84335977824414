export const DRAWER_KEY = {
  ACTIVATION_PENDING: 'activationPending',
  ADDRESS_CHANGE: 'addressChange',
  CALLS: 'calls',
  COMPLETE: 'complete',
  CONFIRM_SERVICE_SCHEDULE: 'confirmServiceSchedule',
  DUE_DATE: 'dueDate',
  EMAIL: 'email',
  FAQ: 'faq',
  MESSAGES: 'messages',
  MODEM: 'modem',
  NEGOTIATION: 'negotiation',
  NONE: '',
  PASSWORD: 'password',
  PHONE: 'phone',
  PLAN: 'plan',
  SERVICES: 'services',
  SERVICE_ORDERS: 'serviceOrders',
  UNLOCK_ACCOUNT: 'unlockAccount',
  VIRTUAL_SUPPORT: 'virtualSupport',
  PROTOCOL: 'protocol',
}
