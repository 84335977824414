import {
  API_KEY,
  API_URL,
  API_URA_URL,
  API_URA_TOKEN,
  API_URA_KEY,
  API_REQUEST_ORIGIN,
  API_INTEGRATION_URL,
  API_FAST_BILLET_URL,
  API_FAST_BILLET_KEY,
  CONTACT_TYPE,
} from 'app-constants'
import { http } from '../use-request'

const options = {
  baseURL: API_URL,
  headers: {
    apikey: API_KEY,
    'x-origin': API_REQUEST_ORIGIN,
  },
}

const optionsPublic = {
  ...options,
  public: true,
}

const optionsIntegration = {
  baseURL: API_INTEGRATION_URL,
  headers: {
    apikey: API_KEY,
    'x-origin': API_REQUEST_ORIGIN,
  },
}

const publicOptionsIntegration = {
  ...optionsIntegration,
  public: true,
}

const optionsUra = {
  baseURL: API_URA_URL,
  headers: {
    token: API_URA_TOKEN,
    apikey: API_URA_KEY,
  },
  public: true,
}

const publicOptionsFastBillet = {
  baseURL: API_FAST_BILLET_URL,
  headers: {
    apikey: API_FAST_BILLET_KEY,
  },
  public: true,
}

function getContracts() {
  return http.get('/v1/contracts', optionsIntegration)
}

function getBankSlips(contractId) {
  return http.get(`/v1/financials/bank-slips/pending?contractId=${contractId}`, optionsIntegration)
}

function getPaidSlips(contractId, page, limit = 5) {
  return http.get(
    `/v1/financials/bank-slips/paid?limit=${limit}&page=${page}&contractId=${contractId}`,
    optionsIntegration
  )
}

function getInvoices(contractId, page, limit = 5) {
  return http.get(
    `/v1/financials/invoices?limit=${limit}&page=${page}&contractId=${contractId}`,
    optionsIntegration
  )
}

function activateHero(idContract) {
  return http.post(`/v1/contracts/${idContract}/hero/activate`, {}, options)
}

function getContacts() {
  return http.get('/v1/contacts', optionsIntegration)
}

function addContact(data) {
  return http.post('/v1/contacts', data, optionsIntegration)
}

function deleteContact(data) {
  return http.delete('/v1/contacts/', { ...optionsIntegration, data })
}

function setMainContact(idContact) {
  return http.patch(`/v1/contacts`, { contact: idContact }, optionsIntegration)
}

function updateContact(idContact, data) {
  return http.put(`/v1/contacts/${idContact}`, data, optionsIntegration)
}

function getCustomer() {
  return http.get('/v1/customers', optionsIntegration)
}

function changePassword(data) {
  return http.put('/v1/customers/password', data, optionsIntegration)
}

function resetPassword(data) {
  return http.post('/v1/customers/password/reset/', data, publicOptionsIntegration)
}

function sendEmailConfirmation(contact) {
  return http.post('/v1/customers/email/send-confirmation', { contact }, optionsIntegration)
}

function confirmEmail(token, contact) {
  return http.post(
    `/v1/customers/email/confirmation`,
    { token, contact, contactType: CONTACT_TYPE.EMAIL },
    optionsIntegration
  )
}

function sendCellphoneConfirmation(contact) {
  return http.post(`/v1/customers/cellphone/send-confirmation`, { contact }, optionsIntegration)
}

function confirmCellphone(token, contact) {
  return http.post(
    '/v1/customers/cellphone/confirmation',
    { token, contact, contactType: CONTACT_TYPE.PHONE },
    optionsIntegration
  )
}

function getWifi(idContract, serialONU) {
  return http.get(`/service/wifi/${idContract}/${serialONU}`, options)
}

function updateWifi(idContract, data) {
  return http.put(`/v1/services/wifi/${idContract}`, data, optionsIntegration)
}

function getTelephony(idContract) {
  return http.get(`/service/telephony/${idContract}`, options)
}

function togglePaidCall(idContract, data) {
  return http.put(`/v1/services/telephony/collect/call/${idContract}`, data, optionsIntegration)
}

function getCallExtract(idRecord) {
  return http.get(`/financial/call/extract/${idRecord}`, options)
}

function getCallRecord(contractId) {
  return http.get(`/v1/financials/call/records`, {
    params: { contractId },
    ...optionsIntegration,
  })
}

function acceptTerm(idContract) {
  return http.post(`/contract/accept/term/${idContract}`, {}, options)
}

function unlockContracts(idContract) {
  return http.post(`/v1/contracts/unlock/${idContract}`, {}, optionsIntegration)
}

function getIsEligibleToUnlockContract(idContract) {
  return http.get(`/v1/contracts/unlock/${idContract}/eligible`, optionsIntegration)
}

function updateAddress(idContract, data) {
  return http.put(`/contract/address/${idContract}`, { ...data, deviceType: 'WEB' }, options)
}

function updateInstalation(idContract) {
  return http.put(`/contract/instalation/${idContract}`, { deviceType: 'WEB' }, options)
}

function getInternet(idContract) {
  return http.get(`/v1/services/internet/${idContract}`, optionsIntegration)
}

function getMotv(idContract) {
  return http.get(`/service/motv/${idContract}`, options)
}

function uploadFile(idContract, file) {
  const formData = new FormData()

  const config = { ...options }
  config.headers.mimeType = 'multipart/form-data'

  formData.append('file', file)

  return http.post(`/contract/upload/payment-receipt/${idContract}`, formData, config)
}

function getCallsHistory(contractId, dateInitial, dateFinal) {
  return http.get('/v1/services/telephony/call/history', {
    ...optionsIntegration,
    params: {
      contractId,
      dateInitial,
      dateFinal,
    },
  })
}

function getContractDetails(id) {
  return http.get(`v1/contracts/detail/${id}`, optionsIntegration)
}

function updateDueDateContract(id, body) {
  return http.put(`v1/contracts/due-date/${id}`, body, optionsIntegration)
}

function getDueDateDays() {
  return http.get(`/v1/contracts/due-date`, optionsIntegration)
}

function getContractActivationStatus(id) {
  return http.get(`contract/activation-status/${id}`, options)
}

function sendPasswordRecoverySms(data) {
  return http.post('/v1/customers/password/reset/send', data, publicOptionsIntegration)
}

function confirmPasswordRecoveryToken(data) {
  return http.post('/v1/customers/password/reset/confirm', data, publicOptionsIntegration)
}

function sendUraEvaluation(data) {
  return http.post('/avaliacao', data, optionsUra)
}

function getUraEvaluationProtocol(data) {
  return http.get(`/avaliacao/${data}`, optionsUra)
}

function getUraProtocol(data) {
  return http.get(`/protocolo/${data}`, optionsUra)
}

function getMessages(userId, brand, page, limit = 10) {
  const params = {
    userId,
    brand,
    page,
    limit,
  }
  return http.get(`v1/messages`, { ...optionsIntegration, params })
}

function updateMessages(id, userId) {
  return http.patch(`v1/messages/${id}`, { userId }, optionsIntegration)
}

function deleteMessages(id, userId) {
  return http.delete(`v1/messages/${id}`, { ...optionsIntegration, params: { userId } })
}

function getVirtualSupportLink() {
  return http.get(`/v1/services/virtual-support`, options)
}

function requestBooking(data) {
  return http.post('/v1/bookings/address-change', data, optionsIntegration)
}

function getBookings(params) {
  return http.get('/v1/bookings', { ...optionsIntegration, ...params })
}

function getBookingsRepair(params) {
  return http.get('/v1/bookings', { ...optionsIntegration, ...params })
}

function getBookingsActivation(params) {
  return http.get('/v1/bookings', { ...optionsIntegration, ...params })
}

function sendBookingsRepair(params) {
  return http.post('/v1/bookings/repair', params, optionsIntegration)
}

function getCondominiums(params) {
  const optionsWithParams = { ...options, params }

  return http.get(`/address/condominiums`, optionsWithParams)
}

function getCondominiumViability(params) {
  const optionsWithParams = { ...options, params }

  return http.get('/address/check-condominium-viability', optionsWithParams)
}

function validateZipCode(data) {
  return http.get(`/address/validate-zip-code?zipCode=${data}`, options)
}

function getServiceOrder(contractId) {
  return http.get(`v1/service-orders/address-change?contractId=${contractId}`, options)
}

function getServiceOrderContractEligible(contractId) {
  return http.get(`v1/service-orders/eligible?contractId=${contractId}`, optionsIntegration)
}

function getHouseViability(params) {
  const withParams = {
    ...options,
    params,
  }
  return http.get(`/address/check-house-viability`, withParams)
}

function getAlreadyOpenContactUpdateModal(contractId) {
  return http.get(`v1/contact-confirmation/${contractId}`, optionsIntegration)
}

function sendContactUpdateModalVisualization(contractId) {
  return http.post(`v1/contact-confirmation`, contractId, optionsIntegration)
}

function getMassiveWarningByUnityAndContract({ unity, idContract }) {
  return http.get(`/v1/massive-warnings/unity/${unity}/contract/${idContract}`, optionsIntegration)
}

function getMassiveWarningByOlt({ idContract }) {
  return http.get(`/v1/massive-warnings/olt/${idContract}`, options)
}

function getServiceOrderRepair({ contractId }) {
  return http.get(`/v1/service-orders/repair?contractId=${contractId}`, options)
}

function updateServiceOrder(serviceOrderId, params) {
  return http.put(`/v1/bookings/${serviceOrderId}`, params, optionsIntegration)
}

function getOnuStatus(contractId) {
  return http.get(`/v1/contracts/onu-warning/${contractId}`, options)
}

function changePasswordOnFirstAccess(params) {
  return http.put(`/v1/customers/password/first-access`, params, options)
}

function getServiceOrders(contractId) {
  return http.get(`/v1/service-orders/${contractId}`, optionsIntegration)
}

function getCouponCode() {
  return http.post('/v1/customers/generate-coupon', {}, optionsIntegration)
}

function getServiceOrderDetails(contractId, type) {
  return http.get(
    `/v1/service-orders/${contractId}/detail?serviceOrderType=${type}`,
    optionsIntegration
  )
}

function changeInstallationDate(params, serviceOrderId) {
  return http.put(`/v1/bookings/${serviceOrderId}`, params, optionsIntegration)
}

function getTvPlanLink(params, tvPlan) {
  const withParams = {
    ...options,
    params,
  }

  return http.get(`/v2/contracts/${tvPlan}/activate`, withParams)
}

function getTvActivationLink(params) {
  return http.post('/v1/contracts/activate', params, optionsIntegration)
}

function putCancelServiceOrder(params) {
  return http.put('/v1/service-orders/cancel', params, optionsIntegration)
}

function getVisitTrackingLink(osId) {
  return http.get(`/v1/service-orders/${osId}/tracking`, optionsIntegration)
}

function getPixPaymentInfo(data) {
  return http.post('/v1/financials/payments/qrcode', data, optionsIntegration)
}

function getPaidBillDownloadLink(invoiceId) {
  const params = {
    invoiceId,
  }

  return http.get('/v1/financials/bank-slips/paid/link', {
    params,
    ...optionsIntegration,
  })
}

function getNegotiationEligible(params) {
  return http.get('/v1/financials/negotiations/check-eligibility', {
    params,
    ...optionsIntegration,
  })
}

function getNegotiationInvoices(params) {
  return http.get('/v1/financials/negotiations', {
    params,
    ...optionsIntegration,
  })
}

function postConfirmationNegotiation(params) {
  return http.post('/v1/financials/negotiations/confirm', params, optionsIntegration)
}

function generatePaymentLink(params) {
  return http.post('/v2/financials/payments/generate-link', params, optionsIntegration)
}

function getFastBilletBankSlips(document) {
  return http.get('/', {
    params: {
      document,
    },
    ...publicOptionsFastBillet,
  })
}

function createBankSlips(params) {
  return http.post('/financial/bank-slips/pdf', params, optionsPublic)
}

function getConnectionStatusRequest(params) {
  return http.get('/v1/contracts/connection-status', {
    params,
    ...optionsIntegration,
  })
}

function getFaqs(params) {
  return http.get('/v1/faq', {
    params,
    ...optionsIntegration,
  })
}

function getRequiredPasswordUpdate(userId, brand) {
  return http.get('/v1/password-update', {
    params: { userId, brand },
    ...optionsIntegration,
  })
}

function sendFaqVote(id, body) {
  return http.patch(`/v1/faq/${id}/feedback`, body, optionsIntegration)
}

function getParameter(code) {
  return http.get('/v1/parameters', { params: { code }, ...optionsIntegration })
}

function postConfirmeServiceSchedule(serviceOrderId, scheduleDate) {
  return http.post(
    `/v1/service-orders/confirm`,
    { serviceOrderId, scheduleDate },
    optionsIntegration
  )
}

function getContractFile(contractId) {
  return http.get(`/v1/contracts/term/${contractId}`, { ...options, responseType: 'blob' })
}

function getServiceProtocolHistory() {
  return http.get(`/v1/contracts/services/protocols`, {
    ...optionsIntegration,
  })
}

export {
  getContracts,
  getBankSlips,
  getInvoices,
  activateHero,
  updateContact,
  getContacts,
  addContact,
  deleteContact,
  setMainContact,
  getCustomer,
  changePassword,
  resetPassword,
  sendEmailConfirmation,
  confirmEmail,
  sendCellphoneConfirmation,
  confirmCellphone,
  getWifi,
  getTelephony,
  updateWifi,
  togglePaidCall,
  getCallExtract,
  getCallRecord,
  acceptTerm,
  unlockContracts,
  getIsEligibleToUnlockContract,
  updateAddress,
  updateInstalation,
  getInternet,
  getMotv,
  uploadFile,
  getContractDetails,
  getContractActivationStatus,
  getCallsHistory,
  sendPasswordRecoverySms,
  getPaidSlips,
  sendUraEvaluation,
  getUraProtocol,
  getUraEvaluationProtocol,
  getDueDateDays,
  updateDueDateContract,
  getMessages,
  updateMessages,
  deleteMessages,
  getVirtualSupportLink,
  requestBooking,
  getBookings,
  getBookingsRepair,
  getBookingsActivation,
  sendBookingsRepair,
  getCondominiums,
  getCondominiumViability,
  validateZipCode,
  getServiceOrder,
  getHouseViability,
  sendContactUpdateModalVisualization,
  getAlreadyOpenContactUpdateModal,
  getMassiveWarningByUnityAndContract,
  getServiceOrderRepair,
  getMassiveWarningByOlt,
  updateServiceOrder,
  getOnuStatus,
  changePasswordOnFirstAccess,
  getServiceOrders,
  getCouponCode,
  getServiceOrderDetails,
  changeInstallationDate,
  getTvActivationLink,
  putCancelServiceOrder,
  getPixPaymentInfo,
  getNegotiationEligible,
  getNegotiationInvoices,
  postConfirmationNegotiation,
  getTvPlanLink,
  generatePaymentLink,
  confirmPasswordRecoveryToken,
  getFastBilletBankSlips,
  createBankSlips,
  getConnectionStatusRequest,
  getFaqs,
  getVisitTrackingLink,
  getRequiredPasswordUpdate,
  sendFaqVote,
  getParameter,
  postConfirmeServiceSchedule,
  getPaidBillDownloadLink,
  getServiceOrderContractEligible,
  getContractFile,
  getServiceProtocolHistory,
}
