import React from 'react'
import { useDispatch } from 'react-redux'
import { setDrawer } from 'slices/drawer'
import { DRAWER_KEY, PLAN_STATUS } from 'app-constants'
import { Button } from 'components'
import { STATICS } from 'statics'
import './style.scss'

export function InvalidContractSupport({ contractStatus }) {
  const dispatch = useDispatch()

  function handleClick() {
    dispatch(setDrawer(DRAWER_KEY.NONE))
  }

  function renderCurrentStatus() {
    return PLAN_STATUS[contractStatus]
  }

  return (
    <div className='invalid-contract-support'>
      <span className='container-icon'>
        <STATICS.INFO_CIRCLE className='icon' />
      </span>
      <div className='container-texts'>
        <p className='text'>O status atual do seu contrato é: {renderCurrentStatus()}.</p>

        <p className='text'>
          Para que você possa acessar as opções de suporte, é necessário que o seu contrato esteja
          habilitado.
        </p>

        <p className='text'>
          Em caso de dúvidas, entre em contato com a nossa Central de Atendimento.
        </p>
      </div>
      <div className='container-actions'>
        <Button className='button' size='large' color='primary' onClick={handleClick}>
          Ok, entendi!
        </Button>
      </div>
    </div>
  )
}
