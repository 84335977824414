import { STATICS } from 'statics'
import { DASHBOARD_COMPONENTS_NAMES } from '../common/dashboard'

export const CANCELED_CONTRACT_DASHBOARD_COMPONENTS = [
  {
    type: DASHBOARD_COMPONENTS_NAMES.BILLS_CARD,
    props: {
      className: 'bills',
    },
  },
  {
    type: DASHBOARD_COMPONENTS_NAMES.NEGOTIATION_CARD,
    props: {
      className: 'genericlarge',
      title: 'Negociar Pagamento',
      Icon: STATICS.BILL_CHECK,
      description: 'Negocie suas fatura em aberto',
      buttonLabel: 'Saiba Mais',
    },
  },
  {
    type: DASHBOARD_COMPONENTS_NAMES.NEGOTIATION_PORTAL,
    props: {
      className: 'genericlarge',
      title: 'Portal de negociação',
      Icon: STATICS.NEGOTIATION_PORTAL,
      description: 'Para consulta de créditos disponíveis, acesse o portal de negociação',
      buttonLabel: 'Saiba Mais',
    },
  },
]
