export const TV_PLAN_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
}

export const TV_PLANS_WITH_ACTIVATION_LINK = {
  GLOBO_PLAY: 'GLOBO_PLAY',
  GLOBO_PLAY_DEFAULT_WITH_ADS: 'GLOBO_PLAY_DEFAULT_WITH_ADS',
  GLOBO_PLAY_DEFAULT: 'GLOBO_PLAY_DEFAULT',
  GLOBO_PLAY_PREMIUM: 'GLOBO_PLAY_PREMIUM',
  GIGA_TV: 'GIGA_TV',
}

export const TV_PLAN_STATUS_LABEL = {
  [TV_PLAN_STATUS.ACTIVE]: 'Ir para o site',
  [TV_PLAN_STATUS.INACTIVE]: 'Ativar Assinatura',
}
