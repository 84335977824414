import React from 'react'
import { GenericCard } from 'components'
import { useFirebaseEvent } from 'hooks'
import { FIREBASE_EVENTS, PORTAL_NEGOTIATION_LINK } from 'app-constants'

export function NegotiationPortal({ className, title, buttonLabel, Icon, description }) {
  const { sendEvent } = useFirebaseEvent()

  function handleDrawer() {
    sendEvent(FIREBASE_EVENTS.CLICK_ON_NEGOTIATION_PORTAL)
    window.open(PORTAL_NEGOTIATION_LINK, '_blank')
  }

  return (
    <GenericCard
      className={className}
      title={title}
      buttonLabel={buttonLabel}
      Icon={Icon}
      onClick={handleDrawer}
    >
      {description}
    </GenericCard>
  )
}
