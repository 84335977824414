export const EMAIL_RGX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const MUST_CONTAIN_LETTER_AND_NUMBER_PATTERN = /^.*(\d.*[a-zA-Z]|[a-zA-Z].*\d).*$/

export const NEITHER_WHITESPACE_NOR_COMMA_PATTERN = /^[^\s,]+$/

export const ONLY_DIGITS_PATTERN = /^\d+$/

export const ONLY_CHARACTERS_PATTERN = /^[a-zA-Z]+$/

export const CHARACTERS_AND_DIGITS_ONLY_PATTERN = /^[\d\wç]+$/

export const HAS_SPECIAL_CHARACTERS_PATTERN = /[^0-9a-zA-Z]+/

export const SPECIAL_CHARACTER_ONLY_PATTERN = /^[^0-9a-zA-Z]+$/

export const HAS_CHARACTER_PATTERN = /[a-zA-Z]/

export const HAS_DIGIT_PATTERN = /[0-9]/

export const SPECIAL_CHARACTER_AND_DIGIT_ONLY = /^[^a-zA-Z]+$/

export const SPECIAL_CHARACTER_AND_CHARACTER_ONLY = /^[a-zA-Z][^0-9]+$/

export const WIFI_PASSWORD_REGEXP = /^(?=(.*[a-z]))(?=(.*[A-Z]))(?=(.*\d))[a-zA-Z0-9!"#$%'¨`´~()*+^{}\-./<=>?@[\\\]_]*$/

export const WIFI_NAME_REGEXP = /^[a-zA-Z]+[a-zA-Z0-9_]*$/
