import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NEW_VIRTUAL_SUPPORT_STAGES } from 'app-constants'
import { setStage } from 'slices/drawer'
import { Button } from 'components/button'
import './style.scss'

export function AutoSupportInfo({ serviceOrder, serviceOrderMassive }) {
  const dispatch = useDispatch()
  const {
    virtualSupportMassive: { message },
  } = useSelector(({ massive }) => massive)

  function handleButtonClick() {
    if (serviceOrderMassive) handleServiceOrderFromMassive()
    else if (message) handleHasMassive()
    else if (serviceOrder) handleServiceOrder()
    else dispatch(setStage(NEW_VIRTUAL_SUPPORT_STAGES.SIGNAL_VERIFY))
  }

  function handleServiceOrderFromMassive() {
    dispatch(setStage(NEW_VIRTUAL_SUPPORT_STAGES.SELECT_ADDRESS))
  }

  function handleHasMassive() {
    dispatch(setStage(NEW_VIRTUAL_SUPPORT_STAGES.MASSIVE_WARNING))
  }

  function handleServiceOrder() {
    dispatch(setStage(NEW_VIRTUAL_SUPPORT_STAGES.SERVICE_ORDER))
  }

  return (
    <div className='auto-support-info'>
      <div className='container-texts'>
        <span className='title'>Problemas de conexão</span>
        <span className='subtitle'>Estamos aqui para te ajudar!</span>
        <span className='description'>
          Vamos trazer algumas soluções à distância para te ajudar com seu problema:
        </span>
      </div>

      <div className='container-actions'>
        <Button className='button' color='primary' size='large' onClick={handleButtonClick}>
          Preciso de ajuda
        </Button>
      </div>
    </div>
  )
}
