import { DRAWER_KEY } from 'app-constants'
import { useDispatch } from 'react-redux'
import { setDrawer } from 'slices/drawer'
import { Button } from 'components'
import { STATICS } from 'statics'
import './style.scss'

export function ProtocolEmpty() {
  const dispatch = useDispatch()

  function handleClick() {
    dispatch(setDrawer(DRAWER_KEY.NONE))
  }

  return (
    <div className='protocol-empty'>
      <div className='header'>
        <div className='icon'>
          <STATICS.INFO_CIRCLE />
        </div>
        <p className='message'>
          Não há registro de protocolo ou histórico de atendimento associado à sua conta no momento.
        </p>
      </div>

      <Button className='button' color='primary' size='large' onClick={handleClick}>
        Ok, entendi!
      </Button>
    </div>
  )
}
