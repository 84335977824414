const fs = require('fs')

function writeFile(file, string) {
  fs.writeFileSync(file, string)

  return true
}

function openNewTab(file) {
  const url = window.URL.createObjectURL(file)
  window.open(url, '_blank').focus()
}

module.exports = {
  writeFile,
  openNewTab,
}
