import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setDrawer } from 'slices/drawer'
import { GenericCard } from 'components'
import { useFirebaseEvent } from 'hooks'
import { FIREBASE_EVENTS, PLAN_STATUS_KEY } from 'app-constants'

export function NegotiationCard({ className, title, buttonLabel, Icon, description }) {
  const dispatch = useDispatch()
  const { sendEvent } = useFirebaseEvent()
  const { contractStatus } = useSelector(({ contract: { selected } }) => ({
    contractStatus: selected.contractStatus,
  }))

  function handleDrawer() {
    const isCanceledContract = contractStatus === PLAN_STATUS_KEY.ST_CONT_CANCELADO
    const event = isCanceledContract
      ? FIREBASE_EVENTS.CLICK_ON_CANCELED_CONTRACT_NEGOTIATION_BILLS
      : FIREBASE_EVENTS.CLICK_ON_BILLET_NEGOTIATION

    sendEvent(event)
    dispatch(setDrawer('negotiation'))
  }

  return (
    <GenericCard
      className={className}
      title={title}
      buttonLabel={buttonLabel}
      Icon={Icon}
      onClick={handleDrawer}
    >
      {description}
    </GenericCard>
  )
}
