import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { fetchBankSlips, fetchInvoices, fetchMessages, fetchPaidInvoices } from 'slices/customer'
import { setDrawer } from 'slices/drawer'
import { setModal, setShouldOpenContactModal } from 'slices/modal'
import { fetchTelephony, fetchContractDetails } from 'slices/contract'
import { clearAlert } from 'slices/alert'
import { fetchMassiveWarningByUnityAndContractId } from 'slices/massive'
import { setPendingConfirmation } from 'slices/serviceOrder'
import { Alert } from 'components'
import {
  CONTACT_TYPE,
  PLACEHOLDER_EMAIL,
  PLAN_STATUS_KEY,
  BRAND_DISPLAY_NAME,
  DASHBOARD_COMPONENTS,
  DEEP_LINKS_DRAWER,
  DRAWER_KEY,
  SERVICE_ORDER_CONFIRMATION,
  CANCELED_CONTRACT_DASHBOARD_COMPONENTS,
} from 'app-constants'
import { getAlreadyOpenContactUpdateModal, getServiceOrderDetails } from 'hooks'
import { getFirstName } from 'utils'
import { DashboardFactory } from './components'
import './style.scss'

export function Dashboard() {
  const [showContactAlert, setShowContactAlert] = useState(false)
  const [showReadjustmentAlert, setShowReadjustmentAlert] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const history = useHistory()

  const {
    idContract,
    readjustmentLink,
    info: { name, contacts },
    alerts,
    unity,
    contractStatus,
  } = useSelector(({ contract: { selected }, customer: { info }, alert }) => ({
    idContract: selected.id,
    readjustmentLink: selected.readjustmentLink,
    info,
    alerts: alert.list,
    unity: selected.unity,
    contractStatus: selected.contractStatus,
  }))

  const shouldOpenContactModal = useSelector(state => state.modal.shouldOpenContactModal)
  const messages = useSelector(({ customer }) => customer.messages)
  const massive = useSelector(state => state.massive)

  const dispatch = useDispatch()

  useEffect(() => {
    async function handleModalContactUpdate() {
      try {
        const { data } = await getAlreadyOpenContactUpdateModal(idContract)

        dispatch(setShouldOpenContactModal(!data.visualized))
      } catch (error) {
        console.error(error)
      }
    }

    dispatch(fetchInvoices(idContract))
    dispatch(fetchPaidInvoices(idContract))
    dispatch(fetchTelephony(idContract))
    dispatch(fetchContractDetails(idContract))
    dispatch(fetchBankSlips(idContract))
    dispatch(fetchMessages())
    handleModalContactUpdate()
  }, [dispatch, idContract])

  useEffect(() => {
    dispatch(fetchMassiveWarningByUnityAndContractId({ unity, idContract }))
  }, [unity, idContract, dispatch])

  useEffect(() => {
    if (alerts.length > 0) window.scrollTo(0, 0)
  }, [alerts])

  useEffect(() => {
    const isMissingConfirmedPhone = contacts
      .filter(({ contactType }) => contactType === CONTACT_TYPE.PHONE)
      .every(contact => !contact.confirmed)

    const isMissingConfirmedEmail = contacts
      .filter(({ contactType }) => contactType === CONTACT_TYPE.EMAIL)
      .every(contact => !contact.confirmed)

    const incompleteSignup =
      contacts.length === 0 || isMissingConfirmedEmail || isMissingConfirmedPhone

    const shouldChangeEmail = contacts.find(
      ({ contact, principal }) => contact.includes(PLACEHOLDER_EMAIL) && principal
    )

    setShowContactAlert(incompleteSignup)
    shouldChangeEmail && dispatch(setModal({ key: 'update_email' }))
  }, [dispatch, contacts])

  useEffect(() => {
    const existsMandatoryUnreadMessage = messages.contentUnread.some(msg => msg.mandatory)
    const existeUnityMassiveWarning = massive.virtualSupportMassive?.message
    const isLoadingMessageOrMassive = messages.isLoading || massive.isLoading
    const isContractAvailable = contractStatus === PLAN_STATUS_KEY.ST_CONT_HABILITADO
    const shouldOpenMessageModal =
      (existsMandatoryUnreadMessage || (existeUnityMassiveWarning && isContractAvailable)) &&
      !isLoadingMessageOrMassive

    if (!modalOpen) {
      const openContactModal = !isLoadingMessageOrMassive && shouldOpenContactModal

      if (shouldOpenMessageModal) {
        setModalOpen(true)
        dispatch(setModal({ key: 'messages' }))
      } else if (openContactModal) {
        setModalOpen(true)
        dispatch(setModal({ key: 'contact_update' }))
      }
    }
  }, [
    dispatch,
    messages.contentUnread,
    modalOpen,
    shouldOpenContactModal,
    messages.isLoading,
    massive.isLoading,
    massive.virtualSupportMassive,
    contractStatus,
  ])

  useEffect(() => {
    setShowReadjustmentAlert(!!readjustmentLink)
  }, [readjustmentLink])

  useEffect(() => {
    async function handleDrawersOnPageInit() {
      if (await handleServicePendingConfirmation()) return

      const isContractActivationPending = contractStatus === PLAN_STATUS_KEY.ST_CONT_EM_ATIVACAO
      if (isContractActivationPending) {
        dispatch(setDrawer(DRAWER_KEY.ACTIVATION_PENDING))
        return
      }

      DEEP_LINKS_DRAWER.forEach(({ query, drawerKey }) => checkDeepLink(query, drawerKey))
    }

    async function handleServicePendingConfirmation() {
      try {
        if (idContract) {
          const response = await Promise.all(
            SERVICE_ORDER_CONFIRMATION.map(type => getServiceOrderDetails(idContract, type))
          )
          const [pendingConfirmation] = response
            .map(
              (os, index) =>
                os && {
                  ...os.data,
                  serviceOrderType: SERVICE_ORDER_CONFIRMATION[index],
                }
            )
            .filter(
              data =>
                data &&
                data.expectedInstallationDate &&
                !data.confirmationScheduleDate &&
                !data.confirmationScheduleOrigin
            )
            .sort((a, b) => {
              const dateA = new Date(a.expectedInstallationDate)
              const dateB = new Date(b.expectedInstallationDate)
              return dateA - dateB
            })

          dispatch(setPendingConfirmation(pendingConfirmation))
          if (pendingConfirmation) {
            dispatch(setDrawer(DRAWER_KEY.CONFIRM_SERVICE_SCHEDULE))
            return true
          }
        }
      } catch (error) {
        console.error(error)
      }
      return false
    }

    function checkDeepLink(query, drawerKey) {
      const search = history?.location?.search || ''

      if (search.includes(query)) {
        history.push('/', { shallow: true })
        dispatch(setDrawer(drawerKey))
      }
    }

    handleDrawersOnPageInit()
  }, [contractStatus, dispatch, history, idContract])

  function handleClick() {
    dispatch(setDrawer('complete'))
  }

  function handleReadjustmentClick() {
    window.open(readjustmentLink)
  }

  function renderContactAlert() {
    return (
      <Alert
        className='alert'
        action={{ label: 'Saiba mais', onClick: handleClick }}
        size='large'
        severity='info'
        title='Você ainda não completou seu cadastro.'
      />
    )
  }

  function renderReadjustmentAlert() {
    return (
      <Alert
        className='alert'
        action={{ label: 'Saiba mais', onClick: handleReadjustmentClick }}
        size='large'
        severity='info'
        title='O seu contrato foi reajustado.'
      />
    )
  }

  function renderDashboardComponentsByContract() {
    const isCanceledContract = contractStatus === PLAN_STATUS_KEY.ST_CONT_CANCELADO
    const components = isCanceledContract
      ? CANCELED_CONTRACT_DASHBOARD_COMPONENTS
      : DASHBOARD_COMPONENTS

    return components.map(({ type, props }) => (
      <DashboardFactory key={type} type={type} props={props} />
    ))
  }

  return (
    <div className='home-page' data-testid='app-test-id'>
      <main className='main'>
        <h1 className='title'>
          Olá <span className='name'>{getFirstName(name)}</span>, você está na sua conta{' '}
          {BRAND_DISPLAY_NAME}
        </h1>
        {showContactAlert && renderContactAlert()}
        {showReadjustmentAlert && renderReadjustmentAlert()}
        {alerts.map(alert => (
          <Alert
            className='alert'
            key={alert.id}
            title={alert.title}
            severity={alert.severity}
            onClose={() => dispatch(clearAlert({ id: alert.id }))}
          />
        ))}
        <div className='cards'>{renderDashboardComponentsByContract()}</div>
      </main>
    </div>
  )
}
