export const MAX_DAYS_OVERDUE_BILL = 60

export const BILLS_TAGS = {
  BREACH_OF_AGREEMENT: 'BREACH_OF_AGREEMENT',
  IN_NEGOTIATION: 'IN_NEGOTIATION',
  UNKNOWN: 'UNKNOWN',
}

export const BILLS_PAYMENT_TYPE = {
  PIX: 'PIX',
  CREDIT_CARD: 'CREDIT_CARD',
  BANK_SLIP: 'BANK_SLIP',
  DEBIT: 'DEBIT',
}
