import { formatDate } from 'utils'
import { DATE_FORMAT } from 'app-constants'
import { PROTOCOL_STATUS_LABEL } from '../../constants'
import './style.scss'

export function ProtocolCard({ protocol, initialDateTime, closed }) {
  function renderHeader() {
    return (
      <div className='protocol-card-header'>
        <h4 className='protocol-card-header-title'>Protocolo de atendimento</h4>
        <div className='protocol-card-header-id'>{protocol || '-'}</div>
      </div>
    )
  }

  function renderFooter() {
    return (
      <div className='protocol-card-footer'>
        <div className='protocol-card-date'>
          <span>Abertura:</span> {formatDate(initialDateTime, DATE_FORMAT.SHORT_DATE_TIME)}
        </div>
        <div className='protocol-card-status'>
          {closed ? PROTOCOL_STATUS_LABEL.CLOSED : PROTOCOL_STATUS_LABEL.OPEN}
        </div>
      </div>
    )
  }
  return (
    <div className='protocol-card'>
      {renderHeader()}
      <hr className='protocol-separator' />
      {renderFooter()}
    </div>
  )
}
