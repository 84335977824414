import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchContractDetails, fetchTvPlansActivationLink } from 'slices/contract'
import { FIREBASE_EVENTS } from 'app-constants'
import { Card } from 'components'
import { useFirebaseEvent } from 'hooks'
import { getTvButtonInfo } from 'utils'
import './style.scss'

export function MyServices() {
  const { sendEvent } = useFirebaseEvent()
  const {
    detail,
    activationLinks,
    selected: { id: idContract },
  } = useSelector(({ contract }) => contract)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchContractDetails(idContract))
    dispatch(fetchTvPlansActivationLink())
  }, [idContract, dispatch])

  function handleGoToWebsiteClick() {
    sendEvent(FIREBASE_EVENTS.CLICK_ON_SVA_GO_TO_WEBSITE)
  }

  function getActivationLink(itemCode) {
    const activationLinkInfo = activationLinks.find(({ tvPlanKey }) => tvPlanKey === itemCode)
    return activationLinkInfo?.activationLink
  }

  function renderSvaButton({ urlDocument }) {
    if (!urlDocument) return null

    return (
      <a
        href={urlDocument}
        target='_blank'
        rel='noopener noreferrer'
        className='link see-details'
        onClick={handleGoToWebsiteClick}
      >
        Ver detalhes
      </a>
    )
  }

  function renderActionButton(additional) {
    const { itemCode, activeSva, status } = additional
    const activationLink = getActivationLink(itemCode)
    const buttonInfo = getTvButtonInfo({ activeSva, status }, activationLink)

    if (!buttonInfo) return renderSvaButton(additional)

    const { link, label } = buttonInfo

    return (
      <>
        <a
          href={link}
          target='_blank'
          rel='noopener noreferrer'
          className='link go-to'
          onClick={handleGoToWebsiteClick}
        >
          {label}
        </a>
        {renderSvaButton(additional)}
      </>
    )
  }

  // TODO: josue.feldeckircher 31/01/2025 - Implementação temporariamente removida na task 8952. Comentado para não perder a implementação do componente. Reavaliar necessidade do código abaixo
  function renderRibbon() {
    // const showRibbon = TV_PLANS_WITH_ACTIVATION_LINK[itemCode]
    // if (!showRibbon) return
    // const isActive = status === TV_PLAN_STATUS.ACTIVE
    // const label = isActive
    //   ? TV_PLAN_STATUS_LABEL[TV_PLAN_STATUS.ACTIVE]
    //   : TV_PLAN_STATUS_LABEL[TV_PLAN_STATUS.INACTIVE]
    // return <Ribbon label={label} isActive={isActive} />
  }

  function renderServiceCards() {
    sendEvent(FIREBASE_EVENTS.CLICK_ON_SVA_DETAILS)
    const additionals = detail?.contractAdditionals || []
    const hasAdditionals = additionals.length

    if (hasAdditionals) {
      return additionals.map(additional => {
        const { itemName, urlImage, urlLogo, description } = additional

        return (
          <Card key={itemName} className='card'>
            {renderRibbon(additional)}
            <div className='container-info'>
              <img src={urlLogo || urlImage} className='img' alt={itemName} />
              <div className='content'>
                <p className='title'>{itemName}</p>
                <p className='info'>{description}</p>
                <div className='actions'>{renderActionButton(additional)}</div>
              </div>
            </div>
          </Card>
        )
      })
    }
    return <p className='message'>O contrato selecionado não possui serviços contratados.</p>
  }
  if (!detail) return null

  return (
    <div className='my-services-component'>
      <h3 className='title'>Meus serviços</h3>
      <h5 className='subtitle'>
        Seus serviços estão listados abaixo, caso deseja obter mais informações ou configurar algo,
        acesse o site dos serviços contratados.
      </h5>
      <p className='text'>Meus serviços</p>
      {renderServiceCards()}
    </div>
  )
}
