/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  pendingConfirmation: {
    id: null,
    scheduleDate: null,
    serviceOrderType: null,
    period: null,
  },
  serviceOrderMassive: false,
}

const serviceOrderSlice = createSlice({
  name: 'serviceOrder',
  initialState,
  reducers: {
    setPendingConfirmation: (state, action) => {
      state.pendingConfirmation = action.payload
    },
    setServiceOrderMassive: (state, action) => {
      state.serviceOrderMassive = action.payload
    },
  },
})

export const { setPendingConfirmation, setServiceOrderMassive } = serviceOrderSlice.actions

export default serviceOrderSlice.reducer
