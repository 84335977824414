import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setDrawerAlert } from 'slices/drawer'
import { ContextLoader } from 'components'
import { getServiceProtocolHistory } from 'hooks'
import { ProtocolCard } from '../protocol-card'
import { ProtocolEmpty } from '../protocol-empty'
import { PROTOCOL_ERROR_MESSAGE } from '../../constants'
import './style.scss'

export function ProtocolList({ protocols, setProtocols }) {
  const [loading, setLoading] = useState(false)
  const { id: contractId } = useSelector(state => state.contract.selected)
  const dispatch = useDispatch()

  useEffect(() => {
    async function fetchProtocols() {
      setLoading(true)
      try {
        const { data } = await getServiceProtocolHistory()
        setProtocols(sortProtocols(data))
      } catch (error) {
        const errorMessage = error?.response?.data?.message || PROTOCOL_ERROR_MESSAGE
        console.error(error)
        dispatch(setDrawerAlert({ severity: 'error', title: errorMessage }))
      } finally {
        setLoading(false)
      }
    }

    fetchProtocols()
  }, [contractId, dispatch, setProtocols])

  function sortProtocols(list) {
    return list.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
  }

  function renderCards() {
    return (
      <div className='protocol-cards'>
        {protocols.map(item => (
          <ProtocolCard
            key={item.id}
            protocol={item.primaryProtocol}
            initialDateTime={item.createdAt}
            closed={!!item.conclusionDate}
          />
        ))}
      </div>
    )
  }

  if (loading) return <ContextLoader fixed={false} />

  if (protocols.length === 0) return <ProtocolEmpty />

  return (
    <div className='protocol-container'>
      <h3 className='protocol-title'>Histórico de atendimentos</h3>
      {renderCards()}
    </div>
  )
}
